<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" class="py-0">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-row dense v-if="loading"> 
                                <v-col cols="12">
                                    <v-skeleton-loader dense type="table-row-divider@15" class="mx-auto"></v-skeleton-loader>
                                </v-col>
                            </v-row>
                            <!-- 表头 -->
                            <v-data-table :headers="headers" :items="defyManagementFeeList" hide-default-footer dense v-if="!loading">
                                <!-- 表数据 -->
                                <template v-slot:body="{ obj }">
                                    <tbody>
                                        <tr v-for="(obj, index) in defyManagementFeeList" :key="index">
                                            <td>{{(indexNo - 1) * pageSize + (index + 1)}}</td>
                                            <td>{{ '$' + common.formatNum(obj.manageFee.toFixed(2)) }}</td>
                                            <td>{{ obj.date }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-data-table>
                            <v-divider v-if="!loading"></v-divider>
                            <!-- 底部分页 -->
                            <template>
                                <div class="text-xs-center" v-if="totalElements > 0">
                                    <v-pagination circle prev-icon="mdi-menu-left" next-icon="mdi-menu-right" v-model="pageNo" :length="totalPage" @input="getDefyManagementFees" :total-visible="20">
                                    </v-pagination>
                                </div>
                            </template>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '@/config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                headers: [
                    { text: 'No', sortable: false },
                    { text: 'Management Fee', sortable: false },
                    { text: 'Date', sortable: false }
                ],
                defyManagementFeeList: [],
                loading: false,
                indexNo: 0,
                totalElements: 0,
                totalPage: 0,
                pageNo: 1,
                pageSize: 20,
            }
        },
        computed: {
            ...mapGetters([]),
        },
        watch: {
            
        },
        mounted() {
            this.getDefyManagementFees(this.pageNo);
        },
        created() {
           
        },
        methods: {
            // 查询DEFY的管理费
            getDefyManagementFees(pageNo){
                this.loading = true;
                let api = config.baseUrl + '/defy/public/management/fees?pageNo=' + (pageNo - 1) + '&pageSize=' + this.pageSize;
                this.$http.get(api).then(response => {
                    let data = response.data;
                    this.loading = false;
                    if (data.code == 200) {
                        this.defyManagementFeeList = data.result.managementFees;
                        this.totalElements = data.result.totalElements;
                        this.totalPage = Math.ceil(this.totalElements / this.pageSize);
                        this.indexNo = pageNo;
                    }
               })
           }
        }
    }
</script>

<style>

</style>