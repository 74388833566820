<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row>
                <v-col cols="12" class="pb-0">
                    <v-alert :dismissible="false" class="green white--text" dark border="left" icon="fas fa-donate">
						<v-row no-gutters class="align-center mt-n1 mb-n1">
							<v-col>
                        		<span>Pools Fee</span>
							</v-col>
							<v-col class="text-right">
								<v-btn small outlined @click="addDialog = true">New</v-btn>
							</v-col>
						</v-row>
                    </v-alert>
                </v-col>
                <v-col cols="12" class="py-0">
                	<v-row no-gutters>
	                	<v-col cols="6">
	                		<v-skeleton-loader ref="skeleton" type="image" height="200" tile class="ma-5" v-if="defyPerformanceFeesLoading"></v-skeleton-loader>
	            			<v-card class="ma-5 ml-0 green white--text" v-if="!defyPerformanceFeesLoading">
		                    	<div class="card-body pa-4">
			                        <div class="align-items-center media">
			                            <div class="media-body">
			                                <h2>DEFY Performance Fee</h2>
			                                <v-row>
			                                	<v-col cols="6">
			                                		<span class="fs-24 fw-normal" v-if="defyPerformanceFees.performanceFeesTotalValue != null">{{ '$' + common.formatNum(defyPerformanceFees.performanceFeesTotalValue.toFixed(2))}}</span><br>
			                                		<span class="fs-12">Available Performance Fee</span>
			                                	</v-col>
			                                	<v-col cols="3">
			                                		<span class="fs-24 fw-normal" v-if="defyPerformanceFees.currentPerformance != null">{{ (defyPerformanceFees.currentPerformance >= 0 ? '+' : '-') + common.formatNum(((defyPerformanceFees.currentPerformance * 100).toFixed(2))) + '%'}}</span><br>
			                                		<span class="fs-12">Current Performance</span>
			                                	</v-col>
			                                	<v-col cols="3">
			                                		<span class="fs-24 fw-normal" v-if="defyPerformanceFees.lastMintedPerformance != null">{{ (defyPerformanceFees.lastMintedPerformance >= 0 ? '+' : '-') + common.formatNum(((defyPerformanceFees.lastMintedPerformance * 100).toFixed(2))) + '%'}}</span><br>
			                                		<span class="fs-12">Last Minted Performance</span>
			                                	</v-col>
			                                </v-row>
			                                <v-row>
			                                	<v-col cols="6">
			                                		<span class="fs-24 fw-normal">{{ '$' + common.formatNum(defyPerformanceFees.unmintedToken) }}</span><br>
			                                		<span class="fs-12">Unminted Token</span>
			                                	</v-col>
			                                	<v-col cols="3">
			                                		<span class="fs-24 fw-normal" v-if="defyPerformanceFees.currentPrice != null">{{ common.formatNum((defyPerformanceFees.currentPrice.toFixed(4)))}}</span><br>
			                                		<span class="fs-12">Current Price</span>
			                                	</v-col>
			                                	<v-col cols="3">
			                                		<span class="fs-24 fw-normal" v-if="defyPerformanceFees.mintedPrice != null">{{ common.formatNum((defyPerformanceFees.mintedPrice.toFixed(4)))}}</span><br>
			                                		<span class="fs-12">Minted Price</span>
			                                	</v-col>
			                                </v-row>
			                            </div>
			                        </div>
			                    </div>
			                </v-card>
	            		</v-col>
	            		<v-col cols="6">
	                		<v-skeleton-loader ref="skeleton" type="image" height="200" tile class="ma-5" v-if="defyManagementFeeLoading"></v-skeleton-loader>
	            			<v-card class="ma-5 ml-0 mr-0 green white--text" v-if="!defyManagementFeeLoading">
		                    	<div class="card-body pa-4">
			                        <div class="align-items-center media">
			                            <div class="media-body">
			                                <h2>DEFY Management Fee</h2>
			                                <v-row>
			                                	<v-col cols="12">
			                                		<span class="fs-24 fw-normal" v-if="defyManagementFee.managementFee != null">{{ '$' + common.formatNum(defyManagementFee.managementFee.toFixed(2))}}</span><br>
			                                		<span class="fs-12">Management Fee</span>
			                                	</v-col>
			                                </v-row>
			                                <v-row>
			                                	<v-col cols="12">
			                                		<span class="fs-24 fw-normal"></span><br>
			                                		<span class="fs-12 underline" @click="showDefyManagementFeesTable = !showDefyManagementFeesTable">
			                                			<a>History Data ></a>
			                                		</span>
			                                	</v-col>
			                                </v-row>
			                            </div>
			                        </div>
			                    </div>
			                </v-card>
			            </v-col>
	            	</v-row>
                </v-col>
                <v-col cols="12" class="mb-5" v-if="showDefyManagementFeesTable">
                	<defy-management-fee></defy-management-fee>
                </v-col>
                <v-row class="px-3" v-show="!showDefyManagementFeesTable">
                	<v-col cols="12" class="py-0">
	                	<v-row>
	                		<v-col cols="4" v-for="(pool, index) in pools" :key="pool.poolAddress">
	                			<v-card class="ma-5" :class="[selectedPool == pool ? 'green white--text' : '', index == 0 ? 'ml-0' : '', index == (pools.length - 1) ? 'mr-0' : '']" @click="selectedPool = pool">
			                    	<div class="card-body pa-4">
				                        <div class="align-items-center media">
				                            <div class="media-body">
				                                <h2 >{{ pool.name + ' (' + pool.symbol +  ')' }}</h2>
				                                <span class="fs-24 fw-normal">{{ '$' + common.formatNum(pool.value.toFixed(4))}}</span>
				                            </div>
				                        </div>
				                    </div>
				                </v-card>
	                		</v-col>
	                	</v-row>
	                </v-col>
	                <v-col cols="12" class="my-5" v-if="poolLoading">
	                	<v-row no-gutters>
	                		<v-col cols="4" v-for="i in 3" :key="i">
	                			<v-skeleton-loader ref="skeleton" type="image" height="100" tile class="ma-5"></v-skeleton-loader>
	                		</v-col>
	                	</v-row>
	                	<v-skeleton-loader ref="skeleton" type="list-item-two-line" tile></v-skeleton-loader>
	                </v-col>
	                <v-col cols="12" class="my-5" v-if="!poolLoading">
	                	<div>
	                		<span>Pool Address: </span>
	                		<span class="title_txt ml-5">
	                            <a :href="'https://etherscan.io/address/' + selectedPool.poolAddress" class="del-underline" target="_blank" v-if="selectedPool.poolAddress != null">
	                            	<span class="pure-red--text">{{ selectedPool.poolAddress }}&#8599;</span>
	                            </a>
	                        </span>
	                    </div>
	                	<div>
							<span>Manager Address: </span>
	                		<span class="title_txt ml-5">
	                            <a :href="'https://etherscan.io/address/' + selectedPool.managerAddress" class="del-underline" target="_blank" v-if="selectedPool.managerAddress != null">
	                            	<span class="pure-red--text">{{ selectedPool.managerAddress }}&#8599;</span>
	                            </a>
	                        </span>
	                    </div>
	                </v-col>
	                <v-col cols="12" class="mb-5">
						<v-tabs v-model="tab" color="green">
							<v-tab>Assets</v-tab>
							<v-tab>Transfers</v-tab>
							<v-tab>Analysis</v-tab>
						</v-tabs>
		            </v-col>
					<v-col cols="12" class="mb-15">
						<!-- <v-row dense v-if="poolLoading || loading"> 
	                        <v-col cols="12">
	                            <v-skeleton-loader dense type="table-row-divider@10" class="mx-auto"></v-skeleton-loader>
	                        </v-col>
	                    </v-row> -->
	                    <v-tabs-items v-model="tab">
							<v-tab-item>
								<v-skeleton-loader dense type="table-row-divider@1" v-if="poolLoading || loading"></v-skeleton-loader>
								<v-data-table dense :headers="valueHeaders" hide-default-footer v-if="!poolLoading && !loading">
									<!-- 表数据 -->
									<template v-slot:body>
										<tbody>
											<tr>
												<td>{{ asset.name }}</td>
												<td>{{ asset.symbol }}</td>
												<td class="text-right">{{ common.formatNum(asset.balance) }}</td>
												<td class="text-right">{{ '$' + common.formatNum(asset.price) }}</td>
												<td class="text-right">{{ '$' + common.formatNum(asset.value) }}</td>
											</tr>
										</tbody>
									</template>
								</v-data-table>
							</v-tab-item>
							<v-tab-item>
								<v-skeleton-loader dense type="table-row-divider@10" v-if="poolLoading || loading"></v-skeleton-loader>
								<v-data-table dense :headers="headers" :items="transfers" hide-default-footer v-if="!poolLoading && !loading">
									<!-- 表数据 -->
									<template v-slot:body="{ transfer }">
										<tbody>
											<tr v-for="(transfer, index) in transfers" :key="transfer.hash">
												<td>
													<a :href="'https://etherscan.io/tx/' + transfer.hash" class="del-underline" target="_blank" v-if="transfer.hash != null">
						                            	<span class="pure-red--text">{{ transfer.hash.substring(0, 8) + '...' + transfer.hash.substring(transfer.hash.length - 8) }}&#8599;</span>
						                            </a>
												</td>
												<td>{{ transfer.fromAddress }}</td>
												<td>{{ transfer.toAddress }}</td>
												<td class="text-right">{{ common.formatNum(transfer.quantity) }}</td>
												<td>{{ transfer.datetime }}</td>
												<td>
													<v-btn x-small outlined tile class="green--text mr-2" width="60" @click="editItem(transfer)">Edit</v-btn>
													<v-btn x-small outlined tile class="red--text" width="60" @click="deleteItem(transfer)">Delete</v-btn>
												</td>
											</tr>
										</tbody>
									</template>
								</v-data-table>
							</v-tab-item>
							<v-tab-item>
								<fee-assets-line-chart :address="selectedPool.poolAddress"></fee-assets-line-chart>
							</v-tab-item>
						</v-tabs-items>
					</v-col>
                </v-row>
            </v-row>
			<!-- 新增模态框 -->
			<v-dialog v-model="addDialog"width="500" persistent>
				<v-card>
        			<v-card-title>
						<span class="fs-24">Add Transfer</span>
						<v-spacer></v-spacer>
						<v-icon @click="addDialog = false">fas fa-times</v-icon>
					</v-card-title>
					<v-card-text>
						<v-row dense>
							<v-col cols="12">
								<v-select :items="addPoolNames" label="Pool" v-model="addObj.poolName" hide-details></v-select>
							</v-col>
							<v-col cols="12">
								<v-text-field label="Hash" required clearable v-model="addObj.hash" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="From" required clearable v-model="addObj.fromAddress" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="To" required clearable v-model="addObj.toAddress" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="Quantity" required clearable v-model="addObj.quantity" type="Number" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="Time YYYY-MM-ddTHH:MM:SS.000Z" required clearable v-model="addObj.datetime" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-switch v-model="addObj.bePerformanceFee" color="green" :label="'Is Performance Fee : ' + addObj.bePerformanceFee" hide-details></v-switch>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn small class="blue--text" outlined @click="addDialog = false" width="75" tile>Cancel</v-btn>
						<v-btn small class="green--text mr-5" outlined @click="addTransfer()" width="75" tile>Save</v-btn>
                	</v-card-actions>
				</v-card>
			</v-dialog>
			<!-- 编辑模态框 -->
			<v-dialog v-model="editDialog"width="500" persistent>
				<v-card>
        			<v-card-title>
						<span class="fs-24">Edit Transfer</span>
						<v-spacer></v-spacer>
						<v-icon @click="editDialog = false">fas fa-times</v-icon>
					</v-card-title>
					<v-card-text>
						<v-row dense>
							<v-col cols="12">
								<v-text-field label="Id" required clearable v-model="editObj.id" hide-details disabled></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="Pool Address" required clearable v-model="editObj.poolAddress" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="Hash" required clearable v-model="editObj.hash" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="From" required clearable v-model="editObj.fromAddress" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="To" required clearable v-model="editObj.toAddress" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="Quantity" required clearable v-model="editObj.quantity" type="Number" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="Time YYYY-MM-ddTHH:MM:SS.000Z" required clearable v-model="editObj.datetime" hide-details></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-switch v-model="editObj.bePerformanceFee" color="green" :label="'Is Performance Fee : ' + editObj.bePerformanceFee" hide-details></v-switch>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn small class="blue--text" outlined @click="editDialog = false" width="75" tile>Cancel</v-btn>
						<v-btn small class="green--text mr-5" outlined @click="saveEditTransfer()" width="75" tile>Save</v-btn>
                	</v-card-actions>
				</v-card>
			</v-dialog>
			<!-- 删除模态框 -->
			<v-dialog v-model="deleteDialog"width="500" persistent>
				<v-card>
        			<v-card-title>
						<span class="fs-24">Delete Transfer</span>
						<v-spacer></v-spacer>
						<v-icon @click="deleteDialog = false">fas fa-times</v-icon>
					</v-card-title>
					<v-card-text>
						<v-row dense>
							<v-col cols="12">
								<v-text-field label="Id" required clearable v-model="deletObj.id" hide-details disabled></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="Pool Address" required clearable v-model="deletObj.poolAddress" hide-details disabled></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="Hash" required clearable v-model="deletObj.hash" hide-details disabled></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="From" required clearable v-model="deletObj.fromAddress" hide-details disabled></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="To" required clearable v-model="deletObj.toAddress" hide-details disabled></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="Quantity" required clearable v-model="deletObj.quantity" type="Number" hide-details disabled></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field label="Time YYYY-MM-ddTHH:MM:SS.000Z" required clearable v-model="deletObj.datetime" hide-details disabled></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-switch v-model="deletObj.bePerformanceFee" color="green" :label="'Is Performance Fee : ' + deletObj.bePerformanceFee" hide-details disabled></v-switch>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn small class="blue--text" outlined @click="deleteDialog = false" width="75" tile>Cancel</v-btn>
						<v-btn small class="red--text mr-5" outlined @click="" width="75" tile @click="deleteTransfer()">Delete</v-btn>
                	</v-card-actions>
				</v-card>
			</v-dialog>
        </v-container>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import { mapGetters } from "vuex";
    import FeeAssetsLineChart from "@/highcharts/FeeAssetsLineChart";
    import DefyManagementFee from "@/pages/pool/DefyManagementFee";
    export default {
        data() {
            return {
                headers: [
                    { text: 'Hash', value: 'hash',width: '10%', sortable: false },
                    { text: 'From', value: 'fromAddress',width: '20%', sortable: false },
                    { text: 'To', value: 'to',width: '20%', sortable: false },
					{ text: 'Quantity', value: 'quantity', width: '20%', align: 'end', sortable: false },
					{ text: 'Time', value: 'timestamp', width: '15%', sortable: false },
					{ text: 'Action', value: '', width: '15%', sortable: false },
                ],
                valueHeaders: [
                	{ text: 'Name', width: '20%', sortable: false },
                    { text: 'Symbol', width: '20%', sortable: false },
                    { text: 'Balance', width: '20%', align: 'end', sortable: false },
                    { text: 'Price', width: '20%', align: 'end', sortable: false },
                    { text: 'Value', width: '20%', align: 'end', sortable: false },
                ],
                tab: null,
                pools: [],
                selectedPool: {},
                transfers: [],
                asset: {},
                fromAddress: '0x0000000000000000000000000000000000000000',
                pool: {},
                poolLoading: false,
				loading:false,
				// 模态框
				addDialog:false,
				editDialog:false,
				deleteDialog:false,
				addObj:{
					poolName:null,
					poolAddress:null,
					hash:null,
					fromAddress:null,
					toAddress:null,
					quantity:null,
					datetime:null,
					bePerformanceFee:true,
				},
				editObj:{},
				deletObj:{},
				defyPerformanceFeesLoading: false,
				defyPerformanceFees: {},
				defyManagementFeeLoading: false,
				defyManagementFee: {},
				// 显示DEFY管理费的表格
				showDefyManagementFeesTable: false,
            }
        },
        computed: {
            ...mapGetters(['user']),
			addPoolNames(){
				var items = [];
				for (let i = 0; i < this.pools.length; i++) {
					const element = this.pools[i];
					items.push(element.name);
				}
				return items;
			},
        },
        components: {
        	FeeAssetsLineChart, DefyManagementFee
        },
        watch: {
            'selectedPool.poolAddress'(newVal, oldVal){
            	this.getTransfers();
            }
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
            this.getDefyPerformanceFee();
            this.getDefyManagementFee();
            this.getPools();
        },
        created() {
           
        },
        methods: {
        	// 查询DEFY的业绩费
        	getDefyPerformanceFee(){
        		this.defyPerformanceFeesLoading = true;
			    let api = config.baseUrl + '/defy/public/performance/unminted-token';
		        this.$http.get(api).then(response => {
		        	let data = response.data;
		        	this.defyPerformanceFeesLoading = false;
	                if (data.code == 200) {
	                   this.defyPerformanceFees = data.result;
	                   // this.defyPerformanceFees.mintedPrice = data.result.mintedPrice;
	                   // this.defyPerformanceFees.unmintedToken = data.result.unmintedToken;
	                   // this.defyPerformanceFees.currentPrice = data.result.currentPrice;
	                   // this.defyPerformanceFees.currentPerformance = data.result.currentPerformance * 100;
	                   // this.defyPerformanceFees.lastMintedPrice = data.result.lastMintedPrice;
	                   // this.defyPerformanceFees.currentPerformance = currentPerformance * 100;
	               }
	           })
        	},
        	// 查询DEFY的管理费
        	getDefyManagementFee(){
        		this.defyManagementFeeLoading = true;
			    let api = config.baseUrl + '/defy/public/management/current-fee';
		        this.$http.get(api).then(response => {
		        	let data = response.data;
		        	this.defyManagementFeeLoading = false;
	                if (data.code == 200) {
	                   this.defyManagementFee = data.result;
	                }
	           })
        	},
        	// 查询所有池子
        	getPools(){
        		this.poolLoading = true;
			    let api = config.baseUrl + '/pool/public/dhedge/pools';
		        this.$http.get(api).then(response => {
		        	this.poolLoading = false;
	                if (response.data.code == 200) {
	                   this.pools = response.data.result;
	                   this.selectedPool = this.pools[0];
	                }
	           })
        	},
		   	// 获取交易数据
		   	getTransfers() {
		   		if (this.selectedPool == null) {
		   			return;
		   		}
			   this.loading = true;
			   let api = config.baseUrl + '/pool/manager/public/fee/' + this.selectedPool.poolAddress;
		       this.$http.get(api).then(response => {
	               if (response.data.code == 200) {
					   this.loading = false;
	                   this.transfers = response.data.result.transfers;
	                   this.pool = response.data.result.pool;
	                   this.asset = response.data.result.asset;
	               }
	           })
		   	},
			// 新增transfer
			addTransfer(){
				if(this.addObj.poolName != null){
					for (let i = 0; i < this.pools.length; i++) {
						const element = this.pools[i];
						if(element.name == this.addObj.poolName){
							this.addObj.poolAddress = element.poolAddress;
						}
					}
				}
				let api = config.baseUrl + "/pool/manager/admin/private/transfer";
				this.$http.post(api,this.addObj).then(res=>{
					let data = res.data;
					if(data.code == 200){
						this.addDialog = false;
						this.getTransfers();
					}else{
						this.$store.dispatch("snackbarMessageHandler",data.message);
					}
				})
			},
			// 点击编辑按钮
			editItem(item){
				this.editDialog = true;
				this.editObj = JSON.parse(JSON.stringify(item));
			},
			// 保存编辑的
			saveEditTransfer(){
				let api = config.baseUrl + "/pool/manager/admin/private/transfer";
				this.$http.post(api,this.editObj).then(res=>{
					let data = res.data;
					if(data.code == 200){
						this.editDialog = false;
						this.getTransfers();
					}else{
						this.$store.dispatch("snackbarMessageHandler",data.message);
					}
				})
			},
			// 点击删除按钮
			deleteItem(item){
				this.deleteDialog = true;
				this.deletObj = JSON.parse(JSON.stringify(item));
			},
			deleteTransfer(){
				console.log(this.deletObj);
				let api = config.baseUrl + "/pool/manager/admin/private/transfer?id=" + this.deletObj.id;
				this.$http.delete(api).then(res=>{
					let data = res.data;
					if(data.code == 200){
						this.deleteDialog = false;
						this.getTransfers();
					}else{
						this.$store.dispatch("snackbarMessageHandler",data.message);
					}
				})
			},
        }
    }
</script>

<style>

</style>