<template>
<div>
    <v-row>
        <v-col cols="12" class="mx-5">
            <div :id="id"></div>
        </v-col>
    </v-row>
</div>
</template>

<style>
svg.highcharts-root {
    font-family: "Lexend Deca", sans-serif !important;
}

.test tspan:active {
    text: expression(target="_blank");
}
</style>

<script>
import config from '@/config.js';
import Highcharts from 'highcharts/highstock';
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            id: 'feeAssetsLineChart',
            chart: null,
            loading: false,
            option: {
                // 图表类型
                chart: {
                    renderTo: 'feeAssetsLineChart',
                    type: 'line',
                    // x方向和y方向都可以用鼠标拖拽放大
                    zoomType: 'xy'
                },
                // 标题
                title: {
                    text: null,
                    align: 'left',
                    style: {
                        color: '#9E9E9E',
                    },
                },
                // x轴
                xAxis: {
                    categories: [],
                    lineWidth: 0,
                    tickInterval: 4,
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S',
                        second: '%m/%d/%Y %H:%M:%S',
                        minute: '%m/%d/%Y %H:%M',
                        hour: '%m/%d/%Y %H:%M',
                        day: '%m/%d',
                        week: '%m/%d',
                        month: '%m/%Y',
                        year: '%Y'
                    },
                    labels: {
                        enabled: false
                    }
                },
                // y轴
                yAxis: {
                    title: {
                        text: null
                    },
                    offset: 40,
                    labels: {
                        align: "left",
                        x: -10
                    },
                    visible: true,
                    // 横线宽度设置为0表示不显示横线
                    gridLineWidth: 0,
                    opposite: true
                },
                // 鼠标悬浮提示框
                tooltip: {
                    valueDecimals: 2,
                    valuePrefix: '$',
                    valueSuffix: '',
                    followTouchMove: true,
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S UTC',
                        second: '%m/%d/%Y %H:%M:%S UTC',
                        minute: '%m/%d/%Y %H:%M UTC',
                        hour: '%m/%d/%Y %H:%M UTC',
                        day: '%m/%d/%Y %H:%M UTC',
                        week: '%m/%d %H:%M UTC',
                        month: '%m/%Y',
                        year: '%Y'
                    }
                },
                // 数据
                series: [{
                        // type: 'spline',
                        name: 'Fee Asset Value',
                        data: [],
                        color: '#ff0000',
                        lineWidth: 3,
                    }
                ],
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    },
                },
                // 图例导航
                legend: {
                    enabled: false
                },
                // 图形参数
                plotOptions: {
                    line: {
                        lineWidth: 2,
                        cursor: 'pointer',
                        marker: {
                            enabled: false
                        }
                    }
                },
                // 版权信息
                credits: {
                    enabled: false
                },
                exporting: {
                    filename: 'Fee-Assets-Chart',
                    buttons: {
                        contextButton: {
                            align: "left",
                            verticalAlign: "top",
                            x: -10,
                            y: 0
                        }
                    }
                },
                navigation: {
                    buttonOptions: {
                        symbolStroke: '#ffffff',
                        theme: {
                            fill: '',
                        },
                    }
                },
                loading: {
                    style: {
                        backgroundColor: 'silver'
                    },
                    labelStyle: {
                        color: 'white',
                        fontSize: '36px'
                    }
                }
            },
        }
    },
    props:['address'],
    watch: {
        address(newVal, oldVal){
            this.getFeeAssets();
        },
        // 监听加载中
        loading(newVal) {
            if (this.chart) {
                if (newVal) {
                    this.chart.showLoading();
                } else {
                    this.chart.hideLoading();
                }
            }
        },
    },
    created() {

    },
    mounted() {
        this.getFeeAssets();
        this.renderChart();
    },
    computed: {
        ...mapGetters([]),
    },
    components: {

    },
    methods: {
        getFeeAssets() {
            if (this.address==null) {
                return;
            }
            this.loading = true;
            this.option.series[0].data = [];
            this.option.xAxis.categories = [];
            let api = config.baseUrl + "/pool/manager/public/fee/assets/" + this.address;
            this.$http.get(api).then(res => {
                let data = res.data;
                if (data.code == 200) {
                    // 重新渲染图表
                    this.option.xAxis.categories = data.result.date;
                    this.option.series[0].data = data.result.data;
                    this.loading = false;
                } else {
                    this.loading = false;
                }
                this.renderChart();
            }, error => {
                this.loading = false;
            })
        },
        // 渲染图表
        renderChart() {
            this.chart = Highcharts.chart(this.id, this.option);
        },
    }
}
</script>
